import React from 'react';
import { useTranslation } from 'react-i18next';
import LineDash from '../../components/LineDash';
import SectionBlock from '../../components/SectionBlock.js';
import KinmenImage from '../../images/solution/grid/microgrid/kinmen.png';
import ForecastingImage from '../../images/solution/grid/microgrid/forecasting.png';
import PowerflowImage from '../../images/solution/grid/microgrid/powerflow.png';
import SchedulingImage from '../../images/solution/grid/microgrid/scheduling.png';
import MicronesiaImage from '../../images/solution/grid/microgrid/micronesia.png';
import MessageImage from '../../images/solution/grid/microgrid/message.png';
import ReportImage from '../../images/solution/grid/microgrid/report.png';

import SubstationImage from '../../images/solution/grid/substation/substation.jpg';
import MonitoringImage from '../../images/solution/grid/substation/monitoring.png';
import RedundancyImage from '../../images/solution/grid/substation/redundancy.svg';

const Section = () => {
  const { t } = useTranslation('solution-grid-substation');
  const feature = t('feature.description', { returnObjects: true });
  const monitoring = t('monitoring.description', { returnObjects: true });
  const redundancy = t('redundancy.description', { returnObjects: true });
  return (
    <section id="substation">
      <LineDash title={t('title')}>
        <SectionBlock title={t('feature.title')} className="cencel-mt">
          <div className="flex mx-auto my-2">
            <img
              className="flex object-cover"
              src={SubstationImage}
              alt="substation"
            />
          </div>
          <div className="flex flex-col m-2 texe-lg text-[#687393]">
            <div className="flex flex-col mx-auto">
              {feature.map((item) => (
                <div className="flex m-1">
                  <p className="mx-2">●</p>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div>
        </SectionBlock>
        <SectionBlock title={t('monitoring.title')}>
          <div className="flex mx-auto my-2">
            <img
              className="flex object-cover"
              src={MonitoringImage}
              alt="substation"
            />
          </div>
          <div className="flex flex-col m-2 texe-lg text-[#687393]">
            <div className="flex flex-col mx-auto">
              {monitoring.map((item) => (
                <div className="flex m-1">
                  <p className="mx-2">●</p>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div>
        </SectionBlock>
        <SectionBlock title={t('redundancy.title')}>
          <div className="flex mx-auto w-full my-2">
            <img
              className="flex object-cover"
              src={RedundancyImage}
              alt="redundancy"
            />
          </div>
          <div className="flex flex-col m-2 texe-lg text-[#687393]">
            <div className="flex flex-col mx-auto">
              {redundancy.map((item) => (
                <div className="flex m-1">
                  <p className="mx-2">●</p>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div>
        </SectionBlock>
      </LineDash>
    </section>
  );
};

export default Section;
