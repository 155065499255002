import React from 'react';
import { useTranslation } from 'react-i18next';
import LineDash from '../../components/LineDash';
import SectionBlock from '../../components/SectionBlock.js';
import KinmenImage from '../../images/solution/grid/microgrid/kinmen.png';
import ForecastingImage from '../../images/solution/grid/microgrid/forecasting.png';
import PowerflowImage from '../../images/solution/grid/microgrid/powerflow.png';
import SchedulingImage from '../../images/solution/grid/microgrid/scheduling.png';
import MicronesiaImage from '../../images/solution/grid/microgrid/micronesia.png';
import MessageImage from '../../images/solution/grid/microgrid/message.png';
import MonitoringImage from '../../images/solution/grid/microgrid/monitoring.png';
import ReportImage from '../../images/solution/grid/microgrid/report.png';

const Section = () => {
  const { t } = useTranslation('solution-grid-microgrid');
  const kinmen = t('kinmen.descriptions.list', { returnObjects: true });
  const kinmen_images = {
    forecasting: ForecastingImage,
    powerflow: PowerflowImage,
    scheduling: SchedulingImage,
  };
  const kinmen_image = t('kinmen.images', { returnObjects: true }).map(
    (item) => ({
      title: item.title,
      image: kinmen_images[item.image],
    })
  );
  const micronesia = t('micronesia.description.descriptions', {
    returnObjects: true,
  });
  const micronesia_images = {
    message: MessageImage,
    monitoring: MonitoringImage,
    report: ReportImage,
  };
  const micronesia_image = t('micronesia.images', { returnObjects: true }).map(
    (item) => ({
      title: item.title,
      image: micronesia_images[item.image],
    })
  );
  return (
    <section id="microgrid">
      <LineDash title={t('title')}>
        <SectionBlock title={t('kinmen.title')} className="cencel-mt">
          <div className="flex m-2">
            <img className="flex object-cover" src={KinmenImage} alt="kinmen" />
          </div>
          <div className="flex flex-col m-2 texe-lg text-[#687393]">
            <div className="flex m-auto">
              <h3>{t('kinmen.descriptions.title')}</h3>
            </div>
            <div className="flex flex-col mx-auto">
              {kinmen.map((item) => (
                <div className="flex m-1">
                  <p className="mx-2">●</p>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-wrap justify-evenly gap-y-5">
            {kinmen_image.map((item) => (
              <div className="flex flex-col w-[45%]">
                <div className="flex">
                  <img
                    className="flex w-full object-cover"
                    src={item.image}
                    alt="market"
                  />
                </div>
                <div className="flex m-auto texe-lg text-[#687393]">
                  <p>{item.title}</p>
                </div>
              </div>
            ))}
          </div>
        </SectionBlock>
        <SectionBlock title={t('micronesia.title')} className="bg-[#f2f1f0]">
          <div className="flex flex-col">
            <div className="flex m-4">
              <img
                className="flex m-auto object-cover"
                src={MicronesiaImage}
                alt="market"
              />
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col texe-lg text-[#687393]">
                <div className="flex m-auto">
                  <h3>{t('micronesia.description.title')}</h3>
                </div>
                <div className="flex flex-col mx-auto my-2">
                  {micronesia.map((item) => (
                    <div className="flex m-1">
                      <p className="mx-2">●</p>
                      <p>{item}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-evenly gap-y-5">
            {micronesia_image.map((item) => (
              <div className="flex flex-col w-[45%]">
                <div className="flex">
                  <img
                    className="flex w-full object-cover"
                    src={item.image}
                    alt="market"
                  />
                </div>
                <div className="flex m-auto texe-lg text-[#687393]">
                  <p>{item.title}</p>
                </div>
              </div>
            ))}
          </div>
        </SectionBlock>
      </LineDash>
    </section>
  );
};

export default Section;
