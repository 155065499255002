import * as React from 'react';
import Layout from '../../components/layout';
import { useTranslation } from 'react-i18next';
import HeroImage from '../../images/hero-about.png';
import StorageSection from '../../sections/solution/Storage';
import MicrogridSection from '../../sections/solution/Microgrid';
import SubstationSection from '../../sections/solution/Substation';

const SolutionPage = () => {
  const { t } = useTranslation('solution-grid');
  return (
    <Layout pageTitle={t('title')}>
      <div className="relative w-full m-auto object-center overflow-hidden">
        <img className="w-full h-96 object-cover" src={HeroImage} alt="" />
        <div className="absolute inset-0 max-w-5xl m-auto">
          <div className="absolute left-[2rem] top-[2rem]">
            <h1 className="m-1 text-3xl text-white">{t('title')}</h1>
          </div>
        </div>
      </div>
      <StorageSection />
      <MicrogridSection />
      <SubstationSection />
    </Layout>
  );
};

export default SolutionPage;
