import React from 'react';
import { useTranslation } from 'react-i18next';
import LineDash from '../../components/LineDash';
import SectionBlock from '../../components/SectionBlock.js';
import RoundTitle from '../../components/RoundTitle.js';
import {
  ChevronDoubleRightIcon,
  ArrowRightIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import StatusImage from '../../images/solution/grid/storage/taiwan_afc_market_status.svg';
import IntegrationImage from '../../images/solution/grid/storage/integration.svg';
import ProtocolDNPImage from '../../images/solution/grid/storage/protocol/dnp.jpg';
import ProtocolIEC61850Image from '../../images/solution/grid/storage/protocol/iec61850.png';
import ProtocolMODBUSImage from '../../images/solution/grid/storage/protocol/modbus.jpg';
import ProtocolOCPUAImage from '../../images/solution/grid/storage/protocol/ocpua.jpg';
import ProtocolRESTImage from '../../images/solution/grid/storage/protocol/restfulapi.png';
import FeatureImage from '../../images/solution/grid/storage/feature.png';
import AggregationImage from '../../images/solution/grid/storage/aggregation.png';
import AchievementstainankanjiauImage from '../../images/solution/grid/storage/achievements/tainan-kanjiau.png';
import AchievementstainanyongkangImage from '../../images/solution/grid/storage/achievements/tainan-yongkang.png';
import Achievementschiayitaibao1Image from '../../images/solution/grid/storage/achievements/chiayi-taibao-1.jpg';
import Achievementschiayitaibao2Image from '../../images/solution/grid/storage/achievements/chiayi-taibao-2.png';
import AchievementsyilanlongdeImage from '../../images/solution/grid/storage/achievements/yilan-longde.png';
import AchievementschanghuachanghuacoastalImage from '../../images/solution/grid/storage/achievements/changhua-changhuacoastal.jpg';
import AchievementsnewtaipeituchengImage from '../../images/solution/grid/storage/achievements/newtaipei-tucheng.png';
import AchievementsnewtaipeixindianImage from '../../images/solution/grid/storage/achievements/newtaipei-xindian.png';
import AchievementshsinchuhukouImage from '../../images/solution/grid/storage/achievements/hsinchu-hukou.png';
import AchievementshualienImage from '../../images/solution/grid/storage/achievements/hualien.png';
import AchievementskinmenImage from '../../images/solution/grid/storage/achievements/kinmen.jpg';
import AchievementskinmenguningImage from '../../images/solution/grid/storage/achievements/kinmen-guning.png';
import AchievementsyunlindouliuImage from '../../images/solution/grid/storage/achievements/yunlin-douliu.png';
import AchievementstainanrendeImage from '../../images/solution/grid/storage/achievements/tainan-rende.png';

const Section = () => {
  const { t } = useTranslation('solution-grid-storage');
  const market = t('market.description', { returnObjects: true });
  const integration = t('integration.description', { returnObjects: true });
  const protocol = [
    ProtocolOCPUAImage,
    ProtocolMODBUSImage,
    ProtocolDNPImage,
    ProtocolRESTImage,
    ProtocolIEC61850Image,
  ];
  const feature = t('feature.description', { returnObjects: true });
  const feature_image = [
    {
      title: `${t('feature.image.1')}`,
      image: FeatureImage,
    },
    // {
    //   title: `${t('feature.image.2')}`,
    //   image: AggregationImage,
    // },
  ];
  const achievements = t('achievements.achievements', { returnObjects: true });
  const achievements_image = [
    AchievementstainankanjiauImage,
    AchievementstainanyongkangImage,
    Achievementschiayitaibao1Image,
    Achievementschiayitaibao2Image,
    AchievementsyilanlongdeImage,
    AchievementschanghuachanghuacoastalImage,
    AchievementsnewtaipeituchengImage,
    AchievementsnewtaipeixindianImage,
    AchievementshsinchuhukouImage,
    AchievementshualienImage,
    AchievementskinmenImage,
    AchievementskinmenguningImage,
    AchievementsyunlindouliuImage,
    AchievementstainanrendeImage,
  ];
  return (
    <section id="storage">
      <LineDash title={t('title')}>
        <SectionBlock title={t('market.title')}>
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col w-full max-w-5xl mx-auto lg:flex-row">
              <div className="flex flex-col w-full lg:w-1/2 p-4">
                <img
                  className="flex w-full object-cover"
                  src={StatusImage}
                  alt="market"
                />
              </div>
              <div className="flex flex-col w-full lg:w-1/2 p-2 texe-lg text-[#687393]">
                <div className="flex flex-col mx-2 my-auto">
                  {market.map((item) => (
                    <div className="flex flex-row m-2">
                      <p className="mx-2">●</p>
                      <p className='font-medium'>{item}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </SectionBlock>
        <SectionBlock title={t('integration.title')} className="bg-[#f2f1f0]">
          <div className="flex flex-col w-full max-w-5xl mx-auto">
            <div className="flex flex-col justify-items-center w-full p-2 texe-lg text-[#687393]">
              <div className="grid grid-cols-3 justify-items-start justify-start mx-6 my-auto p-6">
                {integration.map((item, i) => (
                  <div className="flex flex-row m-2">
                    <p className="mx-2">●</p>
                    <p className='font-medium'>{item}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full p-4">
              <img
                className="flex w-full object-cover"
                src={IntegrationImage}
                alt="market"
              />
            </div>
          </div>
        </SectionBlock>
        <SectionBlock title={t('protocol.title')} className="storage__full">
          <div className="flex flex-col w-full max-w-5xl lg:max-w-full mx-auto">
            <div className="flex mx-auto my-2 p-2 texe-lg text-[#687393]">
              <p className='font-medium'>{t('protocol.description')}</p>
            </div>
            <div className="flex flex-wrap justify-evenly gap-0 px-10 img__div">
              {protocol.map((item) => (
                <div className='w-1/3 flex flex-wrap justify-center content-center'>
                  <img
                    className="flex object-cover w-28 lg:w-1/2"
                    src={item}
                    alt="market"
                  />
                </div>
              ))}
            </div>
          </div>
        </SectionBlock>
        <SectionBlock title={t('feature.title')} className="bg-[#f2f1f0]">
          <div className="flex flex-col w-full max-w-5xl mx-auto">
            <div className="flex flex-col w-full p-4">
              {feature_image.map((item) => (
                <div className="flex flex-col mx-auto my-4">
                  <div className="mx-auto my-2 hidden">
                    <h3 className="mx-auto">{item.title}</h3>
                  </div>
                  <div className="flex flex-col w-full">
                    <img
                      className="flex w-full object-cover"
                      src={item.image}
                      alt="market"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col m-auto p-2 texe-lg text-[#687393]">
            {feature.map((item) => (
              <div className="flex flex-row m-2">
                <p className="mx-2">●</p>
                <p className='font-medium'>{item}</p>
              </div>
            ))}
          </div>
        </SectionBlock>
        <div className='my-8 bg-[#111111] p-10 flex flex-wrap gap-10'>
          <img
            className="flex w-2/3 object-cover"
            src={AggregationImage}
            alt="market"
          />
          <p className='text-[#D7B375] flex flex-wrap content-center text-center text-3xl'
            dangerouslySetInnerHTML={{ __html: t('feature.image.2') }}
          >
          </p>
        </div>
        <SectionBlock title={t('achievements.title')} className="bg-[#f2f1f0]">
          <div className="flex flex-col w-full max-w-5xl mx-auto">
            <div className="flex flex-col w-full p-2 texe-lg text-[#687393]">
              <div className="flex flex-col mx-6 my-auto p-6">
                <div className="flex flex-row m-auto">
                  <p>{t('achievements.description')}</p>
                </div>
              </div>
              <div className="flex flex-col mx-6 my-auto p-6">
                {achievements.map((item) => (
                  <div className="flex flex-row m-2">
                    <p className="mx-2">●</p>
                    <p className='font-medium'>{item}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex h-40">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {achievements_image.map((item) => (
                  <SwiperSlide>
                    <img
                      className="m-auto w-80 h-40 object-cover rounded-md"
                      src={item}
                      alt="market"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </SectionBlock>
      </LineDash>
    </section>
  );
};

export default Section;
