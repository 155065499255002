import React from 'react';
import RoundTitle from './RoundTitle.js';

const SectionBlock = ({ title, className, children }) => {
  return (
    <div className={className}>
      <div className="flex flex-col w-full max-w-5xl mx-auto my-8">
        <RoundTitle title={title} />
        {children}
      </div>
    </div>
  );
};

export default SectionBlock;
